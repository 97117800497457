import React, { useContext, useState, useEffect, useReducer } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Body, Box, ButtonInput, Card, CollectionFilters, Pagination, SearchResultsGrid, TwoColumn, breakpoints } from 'dma-ui-kit';
import styled from 'styled-components';
import SiteContext from 'site/SiteContext';
import { translateInterfaceText } from 'helpers/translation';
import { filterQuery, history, unpackFilterQuery } from 'helpers/search';
import { buildIIIFImageURL } from 'helpers/collection';
import ImageNotFound from 'assets/missing.png';
import { buildQuery, reducer as queryReducer } from 'search/queries';
import { SearchableSection } from 'search/SearchableSection';
import { ConditionalWrap } from 'helpers/ConditionalWrap';
import useBreakpoint from 'use-breakpoint';

const Container = styled(Box)`
  padding: 0 12px;

  @media (min-width: 1025px) {
      padding: 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 24px;
  }    

  margin-bottom: 10px;
  display: block;
`;

const PaginationContainer = styled(Box)`
  max-width: 320px;
  margin: auto;
  margin-top: 48px;

  @media (min-width: 1025px) {
      max-width: 800px;
  }
  @media (max-width: 1024px) {
      max-width: 640px;
  }    
`;

function compareKeys(a, b) {
  return (a.key < b.key) ? -1 : (a.key > b.key) ? 1 : 0;
}

const rowMapping = {
  mobile: 2,
  tabletSmall: 3,
  tablet: 3,
  desktop: 3,
  desktopLarge: 4
};

const CollectionSearch = React.memo(() => {

    const baseSearchURL = process.env.REACT_APP_SEARCH_BASE_PATH;
    const envir = process.env.REACT_APP_COLLECTION_ENV;
    const { searchPath } = useContext(SiteContext);
    const searchEndpoint = `${baseSearchURL}${envir}${searchPath}_collection_objects/_search`;
        
    const noResultsText = translateInterfaceText("No Results Found");
    const loadingText = translateInterfaceText("Loading");
    const searchText = translateInterfaceText('Search');
    const enterSearchTermText = translateInterfaceText('Enter search term');

    const artistText = translateInterfaceText("Artist");
    const cultureText = translateInterfaceText("Culture");
    const departmentText = translateInterfaceText("Department");
    const mediumText = translateInterfaceText("Medium");
    const onViewText = translateInterfaceText("On View");

    const { search } = useLocation();
    const params = new URLSearchParams(search);

    const initialTerm = params.get('s');
    const initialFilter = unpackFilterQuery(params.get('f'));
    const initialPage = params.get('p');

    const [query, setQuery] = useState(null);
    const [nextTerm, setNextTerm] = useState(null);

    const [options, dispatch] = useReducer(
      queryReducer,
      {  
        type: 'collection',
        page: initialPage || 1,
        term: initialTerm || '',
        limit: 20,
        filters: initialFilter || {}
      },  
    );

    useEffect(() => {
      const nextQuery = buildQuery(options);
      setQuery(nextQuery);
    }, [buildQuery, setQuery, options]);

    const handleFilterChange = ( {filter, item} ) => {
      const { filters } = options;
      const payload = filters ? { ...filters } : { };

      // Delete the value if it matches current, otherwise overlay
      if (payload[filter] && payload[filter] == item)
        delete payload[filter];
      else
        payload[filter] = [ item ];
      
      dispatch({ type: 'setFilters', payload });
      dispatch({ type: 'setPagination', payload: { page: 1, limit: options.limit }});
      history.push(`/art/collection/search?s=${options.term}&f=${filterQuery(payload)}&p=1`);
      window.scrollTo(0, 0);
    };

    const handleTermChange = val => {
      setNextTerm(val);
    };

    const handleTermSubmit = () => {
      dispatch({ type: 'setTerm', payload: nextTerm });
      dispatch({ type: 'setPagination', payload: { page: 1, limit: options.limit }});
      history.push(`/art/collection/search?s=${nextTerm}&f=${filterQuery(options.filters)}&p=1`);
      window.scrollTo(0, 0);
    };

    const handlePageChange = val => {
      dispatch({ type: 'setPagination', payload: { page: val, limit: options.limit }});
      history.push(`/art/collection/search?s=${options.term}&f=${filterQuery(options.filters)}&p=${val}`);
      window.scrollTo(0, 0);
    };

    const { breakpoint } = useBreakpoint(breakpoints);
    
    return (
      <Container>
        <Box mb="24px">
          <ButtonInput
            label={searchText}
            placeholder={options.term || enterSearchTermText}
            onChange={(e) => handleTermChange(e.target.value)}
            onClick={() => handleTermSubmit()}
          />
        </Box>
        
        <SearchableSection query={query} endpoint={searchEndpoint} historyPath={"/art/collection/search"}>
          {({ results, loading }) => {

            const filters = {
              artist: { label: artistText, options: [] },
              culture: { label: cultureText, options: [] },
              department: { label: departmentText, options: [] },
              medium: { label: mediumText, options: [] },
              onview: { label: onViewText, options: [] }
            };

            filters.onview.options['On View'] = {
              count: (results?.aggregations?.onview?.doc_count) ? results.aggregations.onview.doc_count : 0, 
              selected: ((options.filters.onview == 'On View') ? true : false) 
            };

            if (results?.aggregations?.artist?.name)
              results.aggregations.artist.name.sort(compareKeys).map(agg => { 
                if (agg.key.length > 0) 
                  filters.artist.options[agg.key] = { 
                    count: agg.doc_count, 
                    selected: ((options.filters.artist == agg.key) ? true : false)
                  }; 
              });

            if (results?.aggregations?.culture)
              results.aggregations.culture.sort(compareKeys).map(agg => { 
                if (agg.key.length > 0) 
                  filters.culture.options[agg.key] = { 
                    count: agg.doc_count, 
                    selected: ((options.filters.culture == agg.key) ? true : false)
                  }; 
              });

            if (results?.aggregations?.department)
              results.aggregations.department.sort(compareKeys).map(agg => { 
                if (agg.key.length > 0) 
                  filters.department.options[agg.key] = { 
                    count: agg.doc_count, 
                    selected: ((options.filters.department == agg.key) ? true : false)
                  }; 
              });

            if (results?.aggregations?.medium)
              results.aggregations.medium.sort(compareKeys).map(agg => { 
                if (agg.key.length > 0) 
                  filters.medium.options[agg.key] = { 
                    count: agg.doc_count, 
                    selected: ((options.filters.medium == agg.key) ? true : false)
                  }; 
              });

            return(
              <>
                {results?.hits && results.hits.length > 0 && !loading && (
                  <ConditionalWrap
                  condition={breakpoint.includes('desktop')}
                  wrap={children => <TwoColumn variant="wide-right">{children}</TwoColumn>}
                >
                 
                    <CollectionFilters filters={filters} filterLimit={5} onChange={handleFilterChange}  />
                    <div>
                      <SearchResultsGrid cardsPerRow={rowMapping[breakpoint]}>
                          {results.hits.map((result, index) => {
                              const imgSrc = (result.primary_image == -1) ? ImageNotFound : buildIIIFImageURL(result.id, result.primary_image, {size: 200, type: 'square'} );

                              const constituent = result.constituents
                                ? result.constituents
                                    .filter(c => c.role !== "Depicted individual")
                                    .map(c => c.name)
                                    .join('\n')
                                : '';

                              return (
                                  <Link key={index} to={{ pathname: `object/${result.id}`}}>
                                      <Card 
                                          title={result.title} 
                                          caption={constituent} 
                                          image={imgSrc} 
                                          variant="mini"
                                      />
                                  </Link>                        
                              );
                          })}
                      </SearchResultsGrid>
                      <PaginationContainer>
                        <Pagination
                          currentPage={results?.currentPage || 1}
                          pageCount={results?.pageCount || 1}
                          onChange={handlePageChange}
                        />
                      </PaginationContainer>                    
                    </div>
                  </ConditionalWrap>
                )}
                {results?.hits?.length === 0 && !loading && (
                  <Box w="100%" pt="48px">
                    <Body textAlign="center">{noResultsText}</Body>
                  </Box>
                )}
                {loading && (
                  <Box w="100%" pt="48px">
                    <Body textAlign="center">{loadingText}...</Body>
                  </Box>
                )}
              </>
            );
          }}
        </SearchableSection>
      </Container>
    );
});

export default CollectionSearch;
